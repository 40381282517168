'use client';

import { FormField } from '@/types';
import FormFields from '@components/Form/FormFields';
import Honeypot from '@components/Honeypot';
import RawHtml from '@components/RawHtml';
import { useActionState } from 'react';
import { submitForm } from './actions';
import SubmitButton from './SubmitButton';

type Props = {
  fields: FormField[];
  thank_you_text: string;
};

function Form(props: Props) {
  const { fields: formFields, thank_you_text: thankYouText } = props;

  const [state, formAction] = useActionState(submitForm, { status: 'idle' });

  return (
    <form id='formular' className='form streamfield' action={formAction}>
      <Honeypot />
      <input
        type='hidden'
        name='form-fields'
        value={JSON.stringify(
          formFields.map(field => ({
            name: field.clean_name,
            type: field.field_type,
            required: field.required,
          })),
        )}
      />
      <input type='hidden' name='page' value={props?.fields?.[0]?.page_id} />
      <FormFields
        formFields={formFields}
        errors={state.status === 'error' ? state?.errors : undefined}
      />
      {state.status === 'success' ? (
        <div className='message message--success'>
          <RawHtml html={thankYouText} />
        </div>
      ) : null}
      {state.status === 'error' ? (
        <div className='message message--error'>
          <p>Leider ist etwas schief gegangen... Bitte versuchen Sie es später erneut...</p>
          <p>
            Alternativ können Sie Ihre Anfrage auch direkt an die E-Mail-Adresse{' '}
            <a href='mailto:YOUR EMAIL HERE'>YOUR EMAIL HERE</a> senden.
          </p>
        </div>
      ) : null}
      <SubmitButton status={state.status} />
    </form>
  );
}

export default Form;
