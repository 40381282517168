'use client';

import Button from '@components/Button';
import { useFormStatus } from 'react-dom';

type Props = {
  status: string;
};

function SubmitButton(props: Props) {
  const { status } = props;
  const { pending } = useFormStatus();
  return (
    <Button
      primary
      type='submit'
      disabled={pending || status === 'success'}
      aria-disabled={pending}
      className='form__submit'
    >
      Absenden
    </Button>
  );
}

export default SubmitButton;
