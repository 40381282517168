import Checkbox from './Checkbox';
import Fieldset from './Fieldset';
import Form from './Form';
import Input from './Input';
import Label from './Label';
import RadioGroup from './RadioGroup';
import Slider from './Slider';

export { Checkbox, Fieldset, Input, Label, RadioGroup, Slider };
export default Form;
